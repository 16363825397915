// import pathToRegexp from 'path-to-regexp'
import { generatePath } from 'react-router-dom'

export const API_METHODS = { POST: 'POST', GET: 'GET', PUT: 'PUT', DELETE: 'DELETE' }

export const routes = {
    index: { path: '/', label: '' },
    router: { path: '/router/', label: '' },
    // from NDA marketing site
    automation: { path: '/automation/', label: 'Automation' },
    howItWorks: { path: '/how-it-works/', label: 'How It Works' },
    about: { path: '/about/', label: 'About' },
    requestDemo: { path: '/request-demo/', label: 'Request Demo' },
    contact: { path: '/contact/', label: 'Contact' },
    pricing: { path: '/pricing/', label: 'Pricing' },
    team: { path: '/team/', label: 'Team' },
    // solutions: { path: '/solutions/', label: 'Solutions' },
    investmentBanks: { path: '/solutions/investment-banks/', label: 'Investment Banks' },
    privateEquity: { path: '/solutions/private-equity', label: 'Private Equity' },
    salesTeams: { path: '/solutions/sales-teams/', label: 'Sales Teams' },
    resources: { path: '/resources/', label: 'Resources' },
    news: { path: '/news/', label: 'News' },
    faqs: { path: '/resources/faqs/', label: 'FAQs' },

    authLogin: { path: '/login/', label: 'Login' },
    authLogout: { path: '/logout/', label: 'Log out' },
    authRegister: { path: '/register/', label: 'Register' },
    authPasswordHelp: { path: '/password-help/', label: 'Password Help' },
    authPasswordReset: { path: '/password-reset/', label: 'Password Reset' },
    authHandler: { path: '/auth-handler/', label: '' },
    authCreateUser: { path: '/create-user/', label: 'Create User' },
    authCreateAccount: { path: '/create-account/', label: 'Create Account' },
    authCreateSubscription: { path: '/create-subscription/', label: 'Create Subscription' },
    privacyPolicy: { path: '/privacy-policy/', label: 'Privacy Policy' },
    termsOfUse: { path: '/terms-of-use/', label: 'Terms of Use' },
    invitation: { path: '/invitation/:invitationIds/:checksum/', label: 'Invitation' },

    signaturesIndex: { path: '/signatures/:signatureCode/', label: 'Signatures' },
    clientSignatureIndex: { path: '/client-signatures/:checksum/:code/', label: 'Client Signatures Index' },
    counterPartyIndex: { path: '/counter-party/:code/:checksum/', label: 'Counter Party Index' },

    publicOutboundIndex: { path: '/outbound/:campaignCode/', label: 'Outbound' },

    appIndex: { path: '/app/', label: '' },
    // ndas
    ndaCreate: { path: '/app/nda/create/', label: 'Create' },
    ndaView: { path: '/app/nda/:ndaId/', label: 'View', reducerKey: 'engagements', pathId: 'ndaId', objId: 'id', objField: 'company' },
    ndaEdit: { path: '/app/nda/:ndaId/edit/', label: 'Edit' },
    ndaIndex: { path: '/app/nda/', label: 'NDA Dashboard' },

    // engagement reports
    engagementReportsIndex: { path: '/app/account/volume-reports/', label: 'Monthly Volume Reports' },
    // provision
    provisionView: { path: '/app/settings/standards/:standardId/provision/:provisionId/', label: 'Provision' },
    // outbound flow
    outboundIndex: { path: '/app/outbound/', label: 'Outbound Campaigns' },
    outboundCreate: { path: '/app/outbound/create/', label: 'Create' },
    outboundView: { path: '/app/outbound/:outboundId/', label: 'View', reducerKey: 'outboundCampaigns', pathId: 'outboundId', objId: 'id', objField: 'name' },
    // settings
    settingsIndex: { path: '/app/settings/', label: '' },
    standardsIndex: { path: '/app/settings/standards/', label: 'Manage Standards' },
    standardCompare: { path: '/app/settings/standards/:standardId/compare/', label: 'Compare' },
    standardView: { path: '/app/settings/standards/:standardId/', label: 'View' },
    standardCreate: { path: '/app/settings/standards/create/', label: 'Create' },

    modalStandardEdit: { path: '/:pagePath*/m/standards/:standardId/edit/', label: 'Edit' },
    modalStandardFile: { path: '/:pagePath*/m/standards/:standardId/file/', label: 'File' },
    // account
    accountUser: { path: '/app/account/user/', label: 'User' },
    accountAuth: { path: '/app/account/auth/', label: 'Auth' },
    accountInfo: { path: '/app/account/info/', label: 'Info' },
    accountSubscription: { path: '/app/account/subscription/', label: 'Subscription' },
    accountContact: { path: '/app/account/contact/', label: 'Contact' },
    // team
    accountTeam: { path: '/app/account/team/', label: 'Team' },
    accountTeamAddUser: { path: '/app/account/team/add-user/', label: 'Add User' },
    accountTeamEditUser: { path: '/app/account/team/:userId/', label: 'Edit User' },

    // tags
    accountTags: { path: '/app/account/tags/', label: 'Tags' },
    accountTagsCreate: { path: '/app/account/tags/create/', label: 'Create Tag' },
    accountTagsEdit: { path: '/app/account/tags/:tagId/', label: 'Edit Tag' },

    accountIndex: { path: '/app/account/', label: 'Account' },

    // admin
    adminIndex: { path: '/admin/', label: '' },

    adminAccountsIndex: { path: '/admin/accounts/', label: 'Accounts' },
    adminAccountsCreate: { path: '/admin/accounts/create/', label: 'Create' },
    adminAccountsView: { path: '/admin/accounts/:accountId/', label: 'View' },
    adminAccountsUserCreate: { path: '/admin/accounts/:accountId/users/create/', label: 'Create User' },
    adminAccountsUserView: { path: '/admin/accounts/:accountId/users/:userId/', label: 'View User' },

    adminNDAsIndex: { path: '/admin/ndas/', label: 'NDAs' },
    adminNDAsCreate: { path: '/admin/ndas/create/', label: 'Create' },
    adminNDAsView: { path: '/admin/ndas/:ndaId/', label: 'View' },
    adminNDAsViewOld: { path: '/admin/ndas/:ndaId/old/', label: 'Old Version' },


    adminOutboundIndex: { path: '/admin/outbound/', label: 'Outbound' },
    adminOutboundView: { path: '/admin/outbound/:outboundId/', label: 'View' },

    adminModalManageParsingRequest: { path: '/:pagePath*/m/e/:engagementId/pr/:parsingRequestId/manage/', label: 'Parsing Requests' },

    adminStandardsIndex: { path: '/admin/standards/', label: 'Standards' },
    adminStandardsView: { path: '/admin/standards/:standardId/', label: 'View' },

    adminContactIndex: { path: '/admin/contact/', label: 'Contact' },
    adminDemoRequestView: { path: '/admin/contact/demo/:requestId/', label: 'Request Demo' },
    adminContactView: { path: '/admin/contact/:contactId/', label: 'View' },

    adminSettingsIndex: { path: '/admin/settings/', label: 'Settings' },

    adminSettingsProvisions: { path: '/admin/settings/provisions/', label: 'Provisions' },
    adminSettingsProvisionView: { path: '/admin/settings/provisions/:provisionId/', label: 'View' },
    adminSettingsProvisionEdit: { path: '/admin/settings/provisions/:provisionId/edit/', label: 'Edit' },

    adminSettingsSections: { path: '/admin/settings/sections/', label: 'Sections' },
    adminSettingsSectionView: { path: '/admin/settings/sections/:sectionId/', label: 'View' },
    adminSettingsSectionEdit: { path: '/admin/settings/sections/:sectionId/edit/', label: 'Edit' },

    adminSettingsOptions: { path: '/admin/settings/options/', label: 'Options' },
    adminSettingsOptionView: { path: '/admin/settings/options/:optionId/', label: 'View' },
    adminSettingsOptionEdit: { path: '/admin/settings/options/:optionId/edit/', label: 'Edit' },

    adminSettingsMasterFields: { path: '/admin/settings/master-fields/', label: 'Master Fields' },
    adminSettingsMasterFieldCreate: { path: '/admin/settings/master-fields/create/', label: 'Create' },
    adminSettingsMasterFieldView: { path: '/admin/settings/master-fields/:masterFieldId/', label: 'View' },

    adminSettingsJSONEndpoints: { path: '/admin/settings/json-endpoints/', label: 'JSON Endpoints' },

    adminSettingsParserSettings: { path: '/admin/settings/parser-settings/', label: 'Parser Settings' },

    adminSettingsConfigValues: { path: '/admin/settings/config-values/', label: 'Config Values' },
    adminSettingsConfigValueCreate: { path: '/admin/settings/config-values/create/', label: 'Create' },
    adminSettingsConfigValueView: { path: '/admin/settings/config-values/:configId/', label: 'View' },

    adminMLIndex: { path: '/admin/ml/', label: 'ML' },
    adminMLSandbox: { path: '/admin/ml/sandbox/', label: 'Sandbox' },
    adminMLModelsIndex: { path: '/admin/ml/models/', label: 'Models' },
    adminMLModelsCreate: { path: '/admin/ml/models/create/', label: 'Create' },
    adminMLModelsView: { path: '/admin/ml/models/:modelId/', label: 'View' },
    adminMLLabelsCreateFromModel: { path: '/admin/ml/models/:modelId/labels/create/', label: 'Create From Model' },
    adminMLLabelsViewFromModel: { path: '/admin/ml/models/:modelId/labels/:labelId/', label: 'View From Model' },
    adminMLExamplesCreateFromModelAndLabel: { path: '/admin/ml/models/:modelId/labels/:labelId/examples/create/', label: 'Create From Model & Label' },
    adminMLExamplesViewFromModelAndLabel: { path: '/admin/ml/models/:modelId/labels/:labelId/examples/:exampleId/', label: 'View From Model & Label' },

    adminMLLabelsIndex: { path: '/admin/ml/labels/', label: 'Labels' },
    adminMLLabelsCreate: { path: '/admin/ml/labels/create/', label: 'Create' },
    adminMLLabelsView: { path: '/admin/ml/labels/:labelId/', label: 'View' },
    adminMLExamplesCreateFromLabel: { path: '/admin/ml/labels/:labelId/examples/create/', label: 'Create From Label' },
    adminMLExamplesViewFromLabel: { path: '/admin/ml/labels/:labelId/examples/:exampleId/', label: 'View From Label' },

    adminMLChat: { path: '/admin/ml/chat/', label: 'Chat' },

    adminMLChatProvisionsIndex: { path: '/admin/ml/chat-provisions/', label: 'Provisions' },
    adminMLChatProvisionsCreate: { path: '/admin/ml/chat-provisions/create/', label: 'Create' },
    adminMLChatProvisionsView: { path: '/admin/ml/chat-provisions/:provisionId/', label: 'View' },
    adminMLChatProvisionsIssue: { path: '/admin/ml/chat-provisions/:provisionId/issues/:issueId/', label: 'View Issue' },
    adminMLChatProvisionsCreateIssue: { path: '/admin/ml/chat-provisions/:provisionId/issues/create/', label: 'Create Issue' },

    adminMLChatIssuesIndex: { path: '/admin/ml/chat-issues/', label: 'Chat Issues' },
    adminMLChatIssuesCreate: { path: '/admin/ml/chat-issues/create/', label: 'Create' },
    adminMLChatIssuesView: { path: '/admin/ml/chat-issues/:issueId/', label: 'View' },

    adminMLChatStandardsIndex: { path: '/admin/chat-standards/', label: 'Chat Standards' },
    adminMLChatStandardsCreate: { path: '/admin/chat-standards/create/', label: 'Create' },
    adminMLChatStandardsView: { path: '/admin/chat-standards/:standardId/', label: 'View' },

    adminMLChatTemplatesIndex: { path: '/admin/ml/chat-templates/', label: 'Chat Templates' },
    adminMLChatTemplatesView: { path: '/admin/ml/chat-templates/:templateId/', label: 'View' },

    adminMLChatWorkflowsIndex: { path: '/admin/ml/chat-workflows/', label: 'Chat Workflows' },
    adminMLChatWorkflowsCreate: { path: '/admin/ml/chat-workflows/create/', label: 'Create' },
    adminMLChatWorkflowsView: { path: '/admin/ml/chat-workflows/:workflowId/', label: 'Viw' },

    adminMLConceptView: { path: '/admin/ml/concepts/:conceptId/', label: 'Concept' },

    adminMLExamplesIndex: { path: '/admin/ml/examples/', label: 'Examples' },
    adminMLExamplesCreate: { path: '/admin/ml/examples/create/', label: 'Create' },
    adminMLExamplesView: { path: '/admin/ml/examples/:exampleId/', label: 'View' },

    adminMLFilesIndex: { path: '/admin/ml/files/', label: 'Files' },
    adminMLFilesCreate: { path: '/admin/ml/files/create/', label: 'Create' },
    adminMLFilesView: { path: '/admin/ml/files/:fileId/', label: 'View' },

    adminMLFineTuneIndex: { path: '/admin/ml/fine-tunes/', label: 'Fine Tunes' },
    adminMLFineTuneCreate: { path: '/admin/ml/fine-tunes/create/', label: 'Create' },
    adminMLFineTuneView: { path: '/admin/ml/fine-tunes/:fineTuneId/', label: 'View' },

    adminMLSampleDocsIndex: { path: '/admin/ml/sample-docs/', label: 'Sample Docs' },
    adminMLSampleDocsCreate: { path: '/admin/ml/sample-docs/create/', label: 'Create' },
    adminMLSampleDocsView: { path: '/admin/ml/sample-docs/:docId/', label: 'View' },

    adminMLDryRunIndex: { path: '/admin/ml/dry-runs/', label: 'Dry Runs' },
    adminMLDryRunCreate: { path: '/admin/ml/dry-runs/create/', label: 'Create' },
    adminMLDryRunView: { path: '/admin/ml/dry-runs/:dryRunId/', label: 'View' },

    adminMLEmbeddingsIndex: { path: '/admin/ml/embeddings/', label: 'Embeddings' },
    adminMLEmbeddingsCreate: { path: '/admin/ml/embeddings/create/', label: 'Create' },
    adminMLEmbeddingsView: { path: '/admin/ml/embeddings/:labelId/', label: 'View' },
    adminMLEmbeddingsList: { path: '/admin/ml/embeddings/list/', label: 'List' },

    adminMLWYSIWYG: { path: '/admin/ml/wysiwyg/', label: 'WYSIWYG' },
    adminMLWYSIWYGView: { path: '/admin/ml/wysiwyg/:dryRunId/:sampleDocId/', label: 'View' },

    adminMLOpenAIIndex: { path: '/admin/ml/openai/', label: 'OpenAI Index' },
    adminMLVerification: { path: '/admin/ml-verification/', label: 'Verification' },

    adminModalCreateProvision: { path: '/:pagePath*/m/provision/create/', label: 'Create' },
    adminModalCreateSection: { path: '/:pagePath*/m/p/:masterProvisionId/section/create/', label: 'Section' },
    adminModalCreateOption: { path: '/:pagePath*/m/s/:masterSectionId/option/create/', label: 'Option' },

    adminPlayground: { path: '/admin/playground/', label: 'Playground' }
}

export const apiResourceEndpoint = (resource, action, id = null, extraPath = '') => {
    let routeObj = {
        url: `/${resource}/`,
        method: API_METHODS.GET
    }
    // adjust the route
    if (action === 'UPDATE' || action === 'VIEW' || action === 'DELETE') {
        routeObj.url = routeObj.url + `${id}/`
    }
    // adjust the medthod
    if (action === 'CREATE' || action === 'UPDATE') {
        routeObj.method = API_METHODS.POST
    } else if (action === 'DELETE') {
        routeObj.method = API_METHODS.DELETE
    }
    // do we need to append any extra stuff to the url
    if (extraPath !== '') {
        routeObj.url = routeObj.url + extraPath + '/'
    }
    return routeObj
}

export const apiRoutes = {
    webGet: { url: '/', method: API_METHODS.GET },
    // auth related endpoints
    fetchUserDetails: { url: '/users/load-details/', method: API_METHODS.GET },

    // account
    accountCreate: { url: '/accounts/', method: API_METHODS.POST },

    // configuration requests
    configAccountTypesList: { url: '/config/account-types/', method: API_METHODS.GET },

    // engagements
    engagementsList: { url: '/engagements/', method: API_METHODS.GET },
    engagementsView: { url: '/engagements/{engagementId}/', method: API_METHODS.GET },
    engagementsCreate: { url: '/engagements/', method: API_METHODS.POST },
    engagementsUpdate: { url: '/engagements/:engagementId/', method: API_METHODS.POST },
    engagementsDelete: { url: '/engagements/:engagementId/', method: API_METHODS.DELETE },
    engagementsLog: { url: '/engagement-logs/{engagementId}/', method: API_METHODS.GET },
    // engagement file download
    engagementFileDownload: { url: '/engagement-files/:id/download/', method: API_METHODS.GET },
    engagementPublicFileDownload: { url: '/engagement-files/:signatureCode/public-download/', method: API_METHODS.GET },
    engagementPublicRedlineDownload: { url: '/engagement-files/:signatureCode/public-redline-download/', method: API_METHODS.GET },
    // outbound campaigns
    outboundPublicFileDownload: { url: '/outbound-files/:campaignCode/public-download/', method: API_METHODS.GET },
    // standards
    // standardsList: { url: '/standards/', method: API_METHODS.GET },
    // standardsView: { url: '/standards/{standardId}/', method: API_METHODS.GET },
    // standardsCreate: { url: '/standards/', method: API_METHODS.POST },
    // standardsUpdate: { url: '/standards/{standardId}/', method: API_METHODS.POST },
    // standardsDelete: { url: '/standards/{standardId}/', method: API_METHODS.DELETE },
    getStandardProvisionStatus: { url: '/standards/:standardId/provisions/:provisionId/status/', method: API_METHODS.GET },
    // user
    userCreate: { url: '/users/', method: API_METHODS.POST },

    adminEngagementFileDownload: { url: '/admin/engagement-files/:id/download/', method: API_METHODS.GET },
    adminEngagementFileApiLink: { url: '/admin/engagement-files/:id/api-link/', method: API_METHODS.GET },
    adminStandardJSON: { url: '/admin/standards/:id/standard-json/', method: API_METHODS.GET }
}

// export const buildRoutePath = (route, params = {}) => {
//     const toPath = pathToRegexp.compile(route)
//     return toPath(params)
// }

export const buildRoutePath = (route, params = {}) => {
    return generatePath(route, params)
}


export const buildModalPath = (route, fullPagePath, params = {}) => {
    const modal = '/m/'
    const modalIndex = fullPagePath.indexOf(modal)
    let pagePath = fullPagePath
    if (modalIndex !== -1) {
        pagePath = fullPagePath.substr(0, modalIndex + 1)
    }
    let modalRoute = route.substr(route.indexOf(modal) + 1)
    const toPath = generatePath(modalRoute, params)
    return `${pagePath}/${toPath}${window.location.search}`
}

export const buildCloseModalPath = (fullPagePath) => {
    const modal = '/m/'
    const modalIndex = fullPagePath.indexOf(modal)
    let pagePath = fullPagePath
    if (modalIndex !== -1) {
        pagePath = fullPagePath.substr(0, modalIndex + 1)
    }
    return pagePath
}

export const nestedRoutePath = (parent, route, isParent = false) => {
    let nestedRoute = route.substr(parent.length)
    if (isParent) {
        nestedRoute = nestedRoute + '*'
    }
    return nestedRoute
}
